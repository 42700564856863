import { template as template_8d9603c4a3c140c7ba6cf60bc725f8ea } from "@ember/template-compiler";
const FKLabel = template_8d9603c4a3c140c7ba6cf60bc725f8ea(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
